import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { LoaderService } from 'src/app/service/loader.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/pages/common/confirm-dialog/confirm-dialog.component';
import { KodenGetRequest } from 'src/app/interfaces/order';
import { Utils } from 'src/app/const/func-util';
import { CommonConstants } from 'src/app/const/const';
declare var $;

@Component({
  selector: 'app-com-koden',
  templateUrl: './koden.component.html',
  styleUrls: ['./koden.component.scss']
})
export class KodenComponent implements OnInit {
  Const = CommonConstants;

  host_url = window.location.origin;
  login_info = this.sessionSvc.get('staff_login_info');
  company_list = Utils.getCompanyList();
  login_company = Utils.getLoginCompany();
  companyCombo = { values: [], clearable: false, showOnFocus: false, disableSearch: true };
  sekoBaseCombo = { values: [], clearable: true };
  form_data = {
    company_id: null,
    seko_department: null,
    entry_id: null,
    entry_name: null,
    entry_tel: null,
    soke_name: null,
    kojin_name: null,
    moshu_name: null,
    canceled: false,
    not_canceled: true,
  };
  searched = false;
  koden_list = null;

  page_per_count = 20;
  pagination = {
    pages: new Array(),
    current: 0
  };
  receipt_url = null;
  selected_entry = null;
  constructor(
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
    private dialog: MatDialog,
    @Inject(LOCALE_ID) private locale: string,
  ) { }


  ngOnInit() {
    this.initControl();

  }

  initControl() {
    if (this.company_list) {
      this.companyCombo.values = this.company_list.map(value => ({ name: value.base_name, value: value.id, data: value }));
      if (this.login_company) {
        if (this.login_company.base_type === CommonConstants.BASE_TYPE_COMPANY) {
          this.form_data.company_id = this.login_company.id;
          this.sekoBaseCombo.values = Utils.getSekoBaseList(this.login_company);
          if (this.login_info.staff.base.base_type !== CommonConstants.BASE_TYPE_COMPANY) {
            this.form_data.seko_department = this.login_info.staff.base.id;
          }
        } else {
          this.companyCombo.disableSearch = false;
          this.form_data.company_id = this.company_list[0].id;
          this.sekoBaseCombo.values = Utils.getSekoBaseList(this.company_list[0]);
        }
      }
    }
  }
  companyChange(event, sekoBaseComboEm) {
    this.sekoBaseCombo.values = [];
    this.form_data.seko_department = null;
    if (event && event.data) {
      this.sekoBaseCombo.values = Utils.getSekoBaseList(event.data);
    }
    sekoBaseComboEm.clear();
    sekoBaseComboEm.initComponent();
  }

  checkClick(flg_name, ...params: any[]) {

    for (const param of params) {
      if (param) {
        this.form_data[flg_name] = !this.form_data[flg_name];
        return;
      }
    }
  }

  searchKoden() {
    this.sessionSvc.clear('message');
    const request = new KodenGetRequest();
    request.company_id = this.form_data.company_id;
    request.seko_department = this.form_data.seko_department;
    request.entry_id = this.form_data.entry_id;
    request.entry_name = this.form_data.entry_name;
    request.entry_tel = this.form_data.entry_tel;
    request.soke_name = this.form_data.soke_name;
    request.kojin_name = this.form_data.kojin_name;
    request.moshu_name = this.form_data.moshu_name;
    if (this.form_data.canceled && !this.form_data.not_canceled) {
      request.is_cancel = true;
    } else if (!this.form_data.canceled && this.form_data.not_canceled) {
      request.is_cancel = false;
    }
    if (!this.loaderSvc.isLoading) {
      this.loaderSvc.call();
    }
    this.pagination.pages = new Array();
    this.pagination.current = 0;
    this.httpClientService.getKodenList(request).then((response) => {
      Utils.log(response);
      this.searched = true;
      this.koden_list = response;

      if (this.koden_list && this.koden_list.length) {
        for (const koden of this.koden_list) {
          if (koden.entry_detail.entry.entry_ts) {
            koden.entry_detail.entry.entry_ts = new Date(koden.entry_detail.entry.entry_ts);
          }
        }
      }
      this.calcPagination();
      this.loaderSvc.dismiss();

    }).catch(error => {
      this.loaderSvc.dismiss();
    });
  }
  calcPagination() {
    if (!this.koden_list || !this.koden_list.length) {
      return;
    }
    const count = Math.ceil(this.koden_list.length / this.page_per_count);
    for (let i = 1; i <= count; i++) {
      this.pagination.pages.push(i);
    }
    this.pagination.current = 1;
  }
  pageTo(page_num) {
    this.pagination.current = page_num;
  }
  clearForm(companyComboEm, sekoBaseComboEm) {

    this.form_data = {
      company_id: null,
      seko_department: null,
      entry_id: null,
      entry_name: null,
      entry_tel: null,
      soke_name: null,
      kojin_name: null,
      moshu_name: null,
      canceled: true,
      not_canceled: true,
    };
    if (this.login_company) {
      if (this.login_company.base_type === CommonConstants.BASE_TYPE_COMPANY) {
        this.form_data.company_id = this.login_company.id;
        if (this.login_info.staff.base.base_type === CommonConstants.BASE_TYPE_COMPANY) {
          sekoBaseComboEm.clear();
        } else {
          this.form_data.seko_department = this.login_info.staff.base.id;
          sekoBaseComboEm.setValue(this.form_data.seko_department);
        }
      } else {
        this.form_data.company_id = this.company_list[0].id;
        this.sekoBaseCombo.values = Utils.getSekoBaseList(this.company_list[0]);
        sekoBaseComboEm.clear();
        sekoBaseComboEm.initComponent();
      }
      companyComboEm.setValue(this.form_data.company_id);
    }

  }

  showUrl(entry_id) {
    if (!entry_id) {
      return;
    }
    this.receipt_url = this.host_url + '/order/' + entry_id + '/receipt';
    $('#receipt-url').modal({
      centered: false,
      closable: true,
      detachable: false,
      transition: 'fade'
    }).modal('show');
  }

  onFocus(event) {
    event.target.select();
  }

  showOrderCancel(entry) {
    if (!entry) {
      return;
    }
    this.selected_entry = entry;
    $('#order-cancel').modal({
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
  }

  cancelOrder() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        msg1: '申込みをキャンセルします、よろしいですか？',
      },
      width: '100%',
      maxWidth: '450px',
      maxHeight: '90%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        $('#order-cancel').modal('hide');
        this.loaderSvc.call();
        this.httpClientService.cancelEntry(this.selected_entry.id)
          .then(async (response) => {
            Utils.log(response);
            this.sessionSvc.save('message', { type: 'info', title: '申込みをキャンセルしました。' });
            this.selected_entry.cancel_ts = new Date();
            this.loaderSvc.dismiss();
            setTimeout(() => {
              this.selected_entry = null;
            }, 500);
          })
          .catch(error => {
            this.loaderSvc.dismiss();
            this.sessionSvc.save('message', { type: 'error', title: '申込みのキャンセル処理が失敗しました。' });
            setTimeout(() => {
              this.selected_entry = null;
            }, 500);
          });
      }
    });
  }

  closeCancelOrder() {
    setTimeout(() => {
      this.selected_entry = null;
    }, 500);
  }

}
