import { Component, OnInit, Inject, LOCALE_ID, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CalendarOptionsDate } from 'src/app/components/com-calendar/com-calendar.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/pages/common/confirm-dialog/confirm-dialog.component';
import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { LoaderService } from 'src/app/service/loader.service';
import {
  HenreihinGetRequest, HenreihinGetResponse, HenreihinOrderPostRequest, HenreihinPutRequest
} from 'src/app/interfaces/henreihin';
import { SupplierGetRequest } from 'src/app/interfaces/supplier';
import { Utils } from 'src/app/const/func-util';
import { CommonConstants } from 'src/app/const/const';
import { formatDate } from '@angular/common';
import { ComDropdownComponent } from 'src/app/components/com-dropdown/com-dropdown.component';
declare var $;

@Component({
  selector: 'app-com-henreihin',
  templateUrl: './henreihin.component.html',
  styleUrls: ['./henreihin.component.scss']
})
export class HenreihinComponent implements OnInit {
  @ViewChild('companyComboEm', { static: false }) companyComboEm: ComDropdownComponent;
  @ViewChild('supplierComboEm', { static: false }) supplierComboEm: ComDropdownComponent;
  Const = CommonConstants;
  Utils = Utils;
  calendarOptionDate: CalendarOptionsDate = new CalendarOptionsDate();

  host_url = window.location.origin;
  login_info = this.sessionSvc.get('staff_login_info');
  company_list = Utils.getCompanyList();
  login_company = Utils.getLoginCompany();
  companyCombo = { values: [], clearable: false, showOnFocus: false, disableSearch: true };
  supplierCombo = { values: [], clearable: true };
  sekoBaseCombo = { values: [], clearable: true };
  hallCombo = { values: [], clearable: true };
  supplier_list = null;
  form_data = {
    company_id: null,
    seko_department: null,
    hall_id: null,
    supplier_id: null,
    seko_id: null,
    seko_date: null,
    soke_name: null,
    kojin_name: null,
    moshu_name: null,
    none: true,
    unorder: true,
    ordered: false,
    confirmed: false,
    canceled: false,
    not_canceled: true,
  };
  searched = false;
  henreihin_list: Array<HenreihinGetResponse> = null;

  page_per_count = 20;
  pagination = {
    pages: new Array(),
    current: 0
  };
  receipt_url = null;
  all_check = false;

  selected_henreihin: HenreihinGetResponse = null;
  message = null;
  processing = false;
  constructor(
    private router: Router,
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
    private dialog: MatDialog,
    @Inject(LOCALE_ID) private locale: string,
  ) { }


  ngOnInit() {
    this.initControl();
    this.getSupplier(this.form_data.company_id);
    setTimeout(() => {
      $('.checkbox.all_check').checkbox();
    }, 100);
  }

  initControl() {
    if (this.company_list) {
      this.companyCombo.values = this.company_list.map(value => ({ name: value.base_name, value: value.id, data: value }));
      if (this.login_company) {
        if (this.login_company.base_type === CommonConstants.BASE_TYPE_COMPANY) {
          this.form_data.company_id = this.login_company.id;
          this.sekoBaseCombo.values = Utils.getSekoBaseList(this.login_company);
          if (this.login_info.staff.base.base_type !== CommonConstants.BASE_TYPE_COMPANY) {
            this.form_data.seko_department = this.login_info.staff.base.id;
          }
          this.hallCombo.values = Utils.GetAllHallList(this.login_company);
        } else {
          this.companyCombo.disableSearch = false;
          this.form_data.company_id = this.company_list[0].id;
          this.sekoBaseCombo.values = Utils.getSekoBaseList(this.company_list[0]);
          this.hallCombo.values = Utils.GetAllHallList(this.company_list[0]);
        }
      }
    }
  }

  checkClick(flg_name, ...params: any[]) {

    for (const param of params) {
      if (param) {
        this.form_data[flg_name] = !this.form_data[flg_name];
        return;
      }
    }
  }

  async getSupplier(company_id) {
    this.supplier_list = null;
    this.supplierCombo.values = [];
    const request = new SupplierGetRequest();
    request.company_id = company_id;
    await this.httpClientService.getSupplierList(request).then((response) => {
      Utils.log(response);
      this.supplier_list = response;
      this.supplierCombo.values = response.map(value => ({ name: value.name, value: value.id }));

    }).catch(error => {
    });
    this.form_data.supplier_id = null;
    this.supplierComboEm.initComponent();
  }

  companyChange(event, sekoBaseComboEm, hallComboEm) {
    this.sekoBaseCombo.values = [];
    this.hallCombo.values = [];
    this.form_data.seko_department = null;
    this.form_data.hall_id = null;
    if (event && event.data) {
      this.sekoBaseCombo.values = Utils.getSekoBaseList(event.data);
      this.hallCombo.values = Utils.GetAllHallList(event.data);
      this.getSupplier(event.data.id);
    }
    sekoBaseComboEm.clear();
    sekoBaseComboEm.initComponent();
    hallComboEm.clear();
    hallComboEm.initComponent();
  }

  checkItem(event, henreihin: HenreihinGetResponse) {
    if (!henreihin) {
      return;
    }
    if (event.target.classList.contains('icon')) {
      return;
    }
    henreihin.selected = !henreihin.selected;
    for (const item of this.henreihin_list) {
      if (!item.selected) {
        this.all_check = false;
        return;
      }
    }
    this.all_check = true;
  }
  checkAllItem() {
    for (const henreihin of this.henreihin_list) {
      henreihin.selected = this.all_check;
    }
  }

  searchHenreihin() {
    this.sessionSvc.clear('message');
    const request = this.getRequest();
    if (!this.loaderSvc.isLoading) {
      this.loaderSvc.call();
    }
    this.pagination.pages = new Array();
    this.pagination.current = 0;
    this.httpClientService.getHenreihinList(request).then((response) => {
      Utils.log(response);
      this.searched = true;
      this.henreihin_list = response;

      if (this.henreihin_list && this.henreihin_list.length) {
        for (const henreihin of this.henreihin_list) {
          if (henreihin.seko_date) {
            henreihin.seko_date = new Date(henreihin.seko_date);
          }
        }
      }
      this.calcPagination();
      this.loaderSvc.dismiss();

    }).catch(error => {
      this.loaderSvc.dismiss();
    });
  }

  getRequest(): HenreihinGetRequest {
    const request = new HenreihinGetRequest();
    request.company_id = this.form_data.company_id;
    request.seko_department = this.form_data.seko_department;
    request.hall_id = this.form_data.hall_id;
    request.supplier_id = this.form_data.supplier_id;
    request.seko_id = this.form_data.seko_id;
    if (this.form_data.seko_date) {
      request.seko_date = formatDate(this.form_data.seko_date, 'yyyy-MM-dd', this.locale);
    }
    request.soke_name = this.form_data.soke_name;
    request.kojin_name = this.form_data.kojin_name;
    request.moshu_name = this.form_data.moshu_name;
    if (this.form_data.canceled && !this.form_data.not_canceled) {
      request.is_cancel = true;
    } else if (!this.form_data.canceled && this.form_data.not_canceled) {
      request.is_cancel = false;
    }
    request.order_status = new Array();
    if (!!this.form_data.none) {
      request.order_status.push(this.Const.ORDER_STATUS_ID_NONE);
    }
    if (!!this.form_data.unorder) {
      request.order_status.push(this.Const.ORDER_STATUS_ID_UNORDER);
    }
    if (!!this.form_data.ordered) {
      request.order_status.push(this.Const.ORDER_STATUS_ID_ORDERED);
    }
    if (!!this.form_data.confirmed) {
      request.order_status.push(this.Const.ORDER_STATUS_ID_CONFIRMED);
    }
    return request;

  }

  calcPagination() {
    if (!this.henreihin_list || !this.henreihin_list.length) {
      return;
    }
    const count = Math.ceil(this.henreihin_list.length / this.page_per_count);
    for (let i = 1; i <= count; i++) {
      this.pagination.pages.push(i);
    }
    this.pagination.current = 1;
  }

  pageTo(page_num) {
    this.pagination.current = page_num;
  }

  clearForm(sekoBaseComboEm, ...comboEms: any[]) {

    this.form_data = {
      company_id: null,
      seko_department: null,
      hall_id: null,
      supplier_id: null,
      seko_id: null,
      seko_date: null,
      soke_name: null,
      kojin_name: null,
      moshu_name: null,
      none: true,
      unorder: true,
      ordered: false,
      confirmed: false,
      canceled: true,
      not_canceled: true,
    };
    if (this.login_company) {
      if (this.login_company.base_type === CommonConstants.BASE_TYPE_COMPANY) {
        this.form_data.company_id = this.login_company.id;
        this.hallCombo.values = Utils.GetAllHallList(this.login_company);
        if (this.login_info.staff.base.base_type === CommonConstants.BASE_TYPE_COMPANY) {
          sekoBaseComboEm.clear();
        } else {
          this.form_data.seko_department = this.login_info.staff.base.id;
          sekoBaseComboEm.setValue(this.form_data.seko_department);
        }
      } else {
        this.form_data.company_id = this.company_list[0].id;
        this.hallCombo.values = Utils.GetAllHallList(this.company_list[0]);
        this.sekoBaseCombo.values = Utils.getSekoBaseList(this.company_list[0]);
        sekoBaseComboEm.clear();
        sekoBaseComboEm.initComponent();
      }
      this.companyComboEm.setValue(this.form_data.company_id);
    }
    for (const comboEm of comboEms) {
      comboEm.clear();
      if ('initComponent' in comboEm) {
        comboEm.initComponent();
      }
    }

  }

  async showSupplier(henreihin: HenreihinGetResponse) {
    this.message = null;
    if (!henreihin) {
      return;
    }
    this.supplier_list = null;
    this.loaderSvc.call();
    await this.httpClientService.getItem(henreihin.henreihin_id).then((response) => {
      Utils.log(response);
      this.supplier_list = response.item_suppliers.map(v => v.supplier);
      this.loaderSvc.dismiss();
    }).catch(error => {
      this.loaderSvc.dismiss();
    });
    if (!this.supplier_list) {
      return;
    }
    this.restSupplierList();
    this.selected_henreihin = henreihin;
    if (henreihin.supplier_id) {
      const supplier = this.supplier_list.find(v => v.id === henreihin.supplier_id);
      if (supplier) {
        supplier.selected = true;
      }

    }
    $('#supplier').modal({
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
  }

  restSupplierList() {
    if (!this.supplier_list || !this.supplier_list.length) {
      return;
    }
    for (const supplier of this.supplier_list) {
      supplier.selected = false;
    }

  }

  saveHenreihinSupplier() {
    this.message = null;
    this.sessionSvc.clear('message');
    if (this.processing) {
      return;
    }
    let selected_id = 0;
    for (const supplier of this.supplier_list) {
      if (supplier.selected) {
        selected_id = supplier.id;
      }
    }
    if (!selected_id) {
      return;
    }

    this.processing = true;
    const postData = new HenreihinPutRequest();
    postData.supplier = selected_id;

    this.loaderSvc.call();
    let func_name = null;
    if (this.selected_henreihin.henreihin_type === 1) {
      func_name = 'updateHenreihinKoden';
    } else {
      func_name = 'updateHenreihinKumotsu';
    }
    this.httpClientService[func_name](this.selected_henreihin.detail_id, postData)
      .then(async (response) => {
        Utils.log(response);
        await this.searchHenreihin();
        this.sessionSvc.save('message', { type: 'info', title: '発注先情報を更新しました。' });
        this.processing = false;
      })
      .catch(error => {
        this.sessionSvc.save('message', { type: 'error', title: '発注先情報の更新が失敗しました。' });
        this.processing = false;
        this.loaderSvc.dismiss();
      });
    $('#supplier').modal('hide');
    setTimeout(() => {
      this.selected_henreihin = null;
    }, 500);
  }

  selectSupplier(supplier) {
    this.restSupplierList();
    supplier.selected = true;
  }

  supplierCanSave() {
    if (!this.supplier_list || !this.supplier_list.length) {
      return false;
    }
    for (const supplier of this.supplier_list) {
      if (supplier.selected) {
        return true;
      }
    }
    return false;

  }

  closeDialog() {
    setTimeout(() => {
      this.selected_henreihin = null;
    }, 500);
  }

  showUrl(entry_id) {
    if (!entry_id) {
      return;
    }
    this.receipt_url = this.host_url + '/order/' + entry_id + '/receipt';
    $('#receipt-url').modal({
      centered: false,
      closable: true,
      detachable: false,
      transition: 'fade'
    }).modal('show');
  }

  onFocus(event) {
    event.target.select();
  }


  saveOrder() {
    this.sessionSvc.clear('message');
    if (this.processing) {
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        msg1: '発注書の作成を行います、よろしいですか？',
      },
      width: '100%',
      maxWidth: '450px',
      maxHeight: '90%'
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result === true) {
        const postData = new HenreihinOrderPostRequest();
        postData.entry_details = new Array();
        for (const henreihin of this.henreihin_list) {
          if (henreihin.selected) {
            postData.entry_details.push(henreihin.detail_id);
            postData.seko = henreihin.seko_id;
            postData.supplier = henreihin.supplier_id;
          }
        }
        if (!postData.entry_details.length) {
          return;
        }

        this.processing = true;
        this.loaderSvc.call();
        const chk = await this.checkOrderStatus(postData.entry_details);
        if (!chk) {
          this.sessionSvc.save('message', {
            type: 'warning', title: 'データが更新されております。',
            msg: '最新情報を取得し直してから、再度処理を行ってください。'
          });
          this.processing = false;
          this.loaderSvc.dismiss();
          return;
        }
        await this.httpClientService.addOrderHenrei(postData)
          .then(async (response) => {
            Utils.log(response);
            await this.searchHenreihin();
            this.sessionSvc.save('message', { type: 'info', title: '発注書を作成しました。' });
            this.processing = false;
          })
          .catch(error => {
            this.sessionSvc.save('message', { type: 'error', title: '発注書の作成が失敗しました。' });
            this.processing = false;
            this.loaderSvc.dismiss();
          });
      }
    });
  }

  async checkOrderStatus(ids: Array<number>): Promise<boolean> {
    let ret = true;
    const request = this.getRequest();
    await this.httpClientService.getHenreihinList(request).then((response) => {
      Utils.log(response);
      for (const id of ids) {
        const henreihin = response.find(v => v.detail_id === id);
        const henreihin_old = this.henreihin_list.find(v => v.detail_id === id);
        if (!henreihin || !henreihin_old || henreihin.cancel_ts ||
          henreihin.order_status !== henreihin_old.order_status) {
          ret = false;
          break;
        }
      }

    }).catch(error => {
      ret = false;
    });
    return ret;
  }

  exportCsv() {
    const title = '施行番号,施行日,式場名,葬家名,故人名,喪主名,商品名,価格,発注先,発注状況,キャンセル\n';
    let csv_data = null;
    let export_data = title;
    if (this.henreihin_list) {
      const values = this.henreihin_list.map(v => {
        const ret_strings = new Array();
        ret_strings.push(v.seko_id);
        ret_strings.push(v.seko_date ? formatDate(v.seko_date, 'yyyy/MM/dd', this.locale) : '');
        ret_strings.push(v.hall_name ? v.hall_name : '');
        ret_strings.push(v.soke_name ? v.soke_name : '');
        ret_strings.push(v.kojin_name ? v.kojin_name : '');
        ret_strings.push(v.moshu_name ? v.moshu_name : '');
        ret_strings.push(v.henreihin_name ? v.henreihin_name : '');
        ret_strings.push(String(v.henreihin_price));
        ret_strings.push(v.supplier_name ? v.supplier_name : '');
        ret_strings.push(Utils.getOrderStatusName(v.order_status));
        ret_strings.push(v.cancel_ts ? 'キャンセル済' : '');
        return ret_strings.join(',');
      });
      csv_data = values.join('\n');
      export_data += csv_data;
    }
    const now = formatDate(new Date(), 'yyyyMMddHHmmss', this.locale);
    const file_name = '返礼品一覧_' + now + '.csv';
    Utils.exportCsv(file_name, export_data);
  }

  baseCheck(henreihin: HenreihinGetResponse) {
    if (!henreihin) {
      return false;
    }
    if (this.login_company.base_type === this.Const.BASE_TYPE_ADMIN) {
      return false;
    }
    if (henreihin.cancel_ts) {
      return false;
    }
    return true;

  }
  canChangeSupplier(henreihin: HenreihinGetResponse) {
    if (!this.baseCheck(henreihin)) {
      return false;
    }
    if (henreihin.order_status !== this.Const.ORDER_STATUS_ID_NONE) {
      return false;
    }
    return true;
  }


  buttonDisabled(order_status) {
    if (!this.henreihin_list || !this.henreihin_list.length) {
      return true;
    }
    let count = 0;
    let seko_id = null;
    let supplier_id = null;
    for (const henreihin of this.henreihin_list) {
      if (henreihin.selected) {
        count++;
        if (!this.baseCheck(henreihin)) {
          return true;
        }
        if (henreihin.order_status !== order_status) {
          return true;
        }
        if (!henreihin.supplier_id) {
          return true;
        }

        if (!seko_id) {
          seko_id = henreihin.seko_id;
        }
        if (!supplier_id) {
          supplier_id = henreihin.supplier_id;
        }
        if (seko_id !== henreihin.seko_id || supplier_id !== henreihin.supplier_id) {
          return true;
        }
      }
    }
    if (!count) {
      return true;
    }
    return false;

  }

}
