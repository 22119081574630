import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { SessionService } from 'src/app/service/session.service';
import { CommonConstants } from 'src/app/const/const';
import { LoaderService } from 'src/app/service/loader.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { PaymentResultGetRequest, EntryPostRequest } from 'src/app/interfaces/order';
declare var $;

@Component({
  selector: 'app-cus-order-complete',
  templateUrl: './order-complete.component.html',
  styleUrls: ['./order-complete.component.scss']
})
export class OrderCompleteComponent implements OnInit {
  Const = CommonConstants;
  seko_info = this.sessionSvc.get('custom_seko_info');
  entry_no = null;
  order_no = this.sessionSvc.get('order_no');
  contract_code = this.sessionSvc.get('contract_code');
  entry: EntryPostRequest = this.sessionSvc.get('service_entry');
  message = null;
  message_sub = null;
  processing = true;
  mode = null;
  constructor(
    private router: Router,
    private loaderSvc: LoaderService,
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private activatedRoute: ActivatedRoute,
    ) { }

  ngOnInit(): void {
    this.loaderSvc.call();
    this.processing = true;
    if (!this.seko_info) {
      this.router.navigate(['fuho/']);
      return;
    }
    this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
      this.mode = params.get('mode');
    });
    if (this.mode === 'ng') {
      this.message = '内部エラーが発生しました。';
      this.loaderSvc.dismiss();
      this.processing = false;
      return;
    }
    this.entry_no = this.sessionSvc.get('entry_no');
    if (this.mode === '3d' && !this.entry_no) {
      this.getPaymentResult();
    } else {
      this.loaderSvc.dismiss();
      this.processing = false;

    }
  }
  getPaymentResult() {
    const requst = new PaymentResultGetRequest();
    requst.contract_code = this.contract_code;
    requst.order_number = this.order_no;
    this.httpClientService.getPaymentResult(requst).then(async (paymentResponse) => {
      if (!paymentResponse.length) {
        this.message = '内部エラーが発生しました。';
        this.loaderSvc.dismiss();
        this.processing = false;
        return;
      }
      if (paymentResponse[0].result_code === '1') {
        await this.saveEntry();
      } else {
        this.message = 'エラーコード：' + paymentResponse[0].err_code;
        this.message_sub = 'エラー内容：' + paymentResponse[0].err_detail;

      }
      this.loaderSvc.dismiss();
      this.processing = false;

    }).catch(error => {
      this.loaderSvc.dismiss();
      this.processing = false;
    });
  }

  async saveEntry() {
    this.entry.con_count = 2;
    await this.httpClientService.addEntry(this.entry)
      .then((response) => {
        this.entry_no = response.id;
        this.entry.details = null;
        this.entry.epsilon_token = null;
        this.sessionSvc.clearCustomerData();
        this.sessionSvc.save('service_entry', this.entry);
        this.sessionSvc.save('order_no', response.order_number);
        this.sessionSvc.save('entry_no', response.id);
        this.loaderSvc.dismiss();
      }).catch(error => {
        this.message = 'お支払い手続きに失敗しました。';
        if (!error.details) {
          this.message_sub = error.toString();
        }
        this.loaderSvc.dismiss();
      });

    }

  goTop() {
    this.router.navigate(['fuho/' + this.seko_info.seko_company.id + '-' + this.seko_info.id + '/']);
  }

  back() {
    this.router.navigate(['order/payment/']);
  }
}
